import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button } from '../../../../components/base';
import { FINANCE_NON_BUDGET_TABLE_FIELDS } from '../../../../constants/finance/budgeting/nonBudget.constant';
import { commaFormatted, dateFormat } from '../../../../utils/transformer.util';

export default class NonBudget extends PureComponent {
  constructor(props) {
    super(props);
    const { user } = props;
    const { user_group } = user;
    const { permissions = [] } = user_group;
    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.getData = this.getData.bind(this);
    this.renderRows = this.renderRows.bind(this);
    this.renderButtons = this.renderButtons.bind(this);
    this.state = {
      permissions,
    };
  }

  componentDidMount() {
    this.getData();
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/penganggaran/tambah-non-anggaran', { isEdit: false });
  }

  onClickEdit(val) {
    const { history } = this.props;
    const { permissions } = this.state;
    history.push('/dashboard/keuangan/penganggaran/edit-non-anggaran', { data: val, isEdit: permissions.includes('edit_non_budget') });
  }

  async getData() {
    const { handleGetNonBudget } = this.props;
    const res = await handleGetNonBudget();
    this.setState({
      data: res,
    });
  }

  renderButtons(row) {
    const { permissions } = this.state;
    return (
      <>
        { permissions.includes('edit_non_budget') && (
          <Button
            title="Edit"
            onClick={() => this.onClickEdit(row)}
          />
        )}
        { !permissions.includes('edit_non_budget') && (
          <Button
            title="Lihat"
            onClick={() => this.onClickEdit(row)}
          />
        )}
      </>
    );
  }

  renderRows() {
    const { data = {} } = this.state;
    const { result = [] } = data;

    return (
      <>
        { result.map((row, idx) => (
          <tr
            key={row.id}
            onClick={() => this.onClickEdit(row)}
          >
            <td>{idx + 1}</td>
            <td>{row.request_number}</td>
            <td>{dateFormat(row.date)}</td>
            <td className="nominal">{ commaFormatted(row.total) }</td>
            <td>{row.status}</td>
            <td>
              { this.renderButtons(row) }
            </td>
          </tr>
        ))}
      </>
    );
  }

  render() {
    const { permissions } = this.state;
    return (
      <div className="non-budget">
        { permissions.includes('edit_non_budget') && (
          <Button
            title="Tambah Non Anggaran"
            onClick={this.onClickAdd}
          />
        )}
        <div className="non-budget__content">
          <table className="table">
            <thead>
              {
                FINANCE_NON_BUDGET_TABLE_FIELDS.label.map((field, idx) => (
                  <th key={`table_th_${idx}`}>{field}</th>
                ))
              }
            </thead>
            <tbody>
              { this.renderRows() }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

NonBudget.propTypes = {
  handleGetNonBudget: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
NonBudget.defaultProps = {
  handleGetNonBudget: noop,
  user: null,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { successAlert } from '../../../../utils/alert.util';
import ListForm from '../../../../components/ListForm/ListForm.component';
import { commaFormatted, normalizeAmount } from '../../../../utils/transformer.util';
import { FINANCE_ADD_NON_BUDGET_FORM_FIELDS } from '../../../../constants/finance/budgeting/nonBudget.constant';
import { Button, Input } from '../../../../components/base';
import SelectCoa from '../../../../components/SelectCoa/SelectCoa.component';

export default class AddNonBudget extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props;
    const date = new Date();
    const { permissions } = user.user_group;
    this._setForm = this._setForm.bind(this);
    this._searchCoa = this._searchCoa.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onApproval = this._onApproval.bind(this);
    this._onReject = this._onReject.bind(this);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._renderRows = this._renderRows.bind(this);
    this.state = {
      form: {
        value: {
          date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
          is_editable: true,
        },
        error: {},
      },
      is_viewable: true,
      is_editable: true,
      permissions,
    };
  }

  async componentDidMount() {
    const { location } = this.props;
    const { data = {} } = location.state;

    await this._searchCoa();

    this._setForm(data);
  }

  async _searchCoa(keyword = null) {
    const { handleGetCodeOfAccount } = this.props;

    await handleGetCodeOfAccount({
      keyword,
      categories: [51000, 52000, 53000, 54000, 55000, 56000, 13000],
    });
  }

  async _setForm(val) {
    const { id } = val;
    const { handleGetNonBudget } = this.props;
    if (id) {
      const res = await handleGetNonBudget({
        id: val.id,
      });
      if (res) {
        this.setState({
          form: {
            value: {
              ...res.data,
            },
            error: {},
          },
          is_viewable: res.is_viewable,
          is_editable: res.is_editable,
        });
      }
    }
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const {
      inputArray = false,
      arrayPosition = 0, fieldName = '', isNumber = false,
    } = dataset;
    this.setState((prevState) => {
      const { form = {} } = prevState;
      const newValues = JSON.parse(JSON.stringify(form.value));
      if (inputArray) {
        newValues[fieldName][arrayPosition][name] = isNumber ? normalizeAmount(value) : value;
      } else {
        newValues[name] = isNumber ? normalizeAmount(value) : value;
      }

      return {
        form: {
          value: {
            ...prevState.form.value,
            ...newValues,
          },
          error: {
            ...prevState.form.error,
            [name]: '',
          },
        },
      };
    });
  }

  async _onSubmit(e) {
    e.preventDefault();
    const { user } = this.props;
    const { prm_school_units_id, workingUnit } = user;
    const { form } = this.state;
    const {
      handleAddNonBudget, history, location,
      handleEditNonBudget,
    } = this.props;
    const { isEdit = false } = location.state;
    const { value } = form;

    let unit_id = prm_school_units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }
    let val = null;

    if (isEdit) {
      val = await handleEditNonBudget(value, history.goBack, unit_id);
    } else {
      val = await handleAddNonBudget(value, history.goBack, unit_id);
    }

    if (val.data) {
      successAlert({ title: 'Berhasil menyimpan data' });
      this._setForm(val.data);
    }
  }

  async _onApproval(e) {
    e.preventDefault();
    const { user } = this.props;
    const { prm_school_units_id, workingUnit } = user;
    const { permissions } = this.state;
    const { form } = this.state;
    const {
      handleSubmitNonBudget,
    } = this.props;

    if (permissions.includes('recommend_non_budget') || permissions.includes('edit_non_budget') || permissions.includes('final_recommend_non_budget')) {
      await this._onSubmit(e);
    }

    let unit_id = prm_school_units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }

    const res = await handleSubmitNonBudget(form.value, unit_id);
    if (res.data) {
      successAlert({ title: 'Berhasil mengajukan Non Anggaran' });
      this._setForm(res.data);
    }
  }

  async _onReject(e) {
    e.preventDefault();
    const { user } = this.props;
    const { prm_school_units_id, workingUnit } = user;
    const { form } = this.state;
    const {
      handleRejectNonBudget, history,
    } = this.props;

    let unit_id = prm_school_units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }

    handleRejectNonBudget(form.value, history.goBack, unit_id);
  }

  _onAddList() {
    const { form = {} } = this.state;
    const { value = {} } = form;
    const { details = [] } = value;
    const newDetails = JSON.parse(JSON.stringify(details));

    newDetails.push({
      coa: {},
      nominal: 0,
    });

    this.setState(prevState => (
      {
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            details: newDetails,
          },
        },
      }
    ));
  }

  _onDeleteList(index) {
    const { form = {} } = this.state;
    const { value = {} } = form;
    const { details = [] } = value;
    const newDetails = JSON.parse(JSON.stringify(details));

    newDetails.splice(index, 1);
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          details: newDetails,
        },
      },
    }));
  }

  _renderRows() {
    const { form = {}, is_editable, permissions } = this.state;
    const { value = {} } = form;
    const { details = [], is_approved, workflow = [] } = value;
    const { coa } = this.props;
    const rows = [];
    const hasWorkflow = workflow.length > 0;
    const canRecommend = permissions.includes('recommend_non_budget') && !is_approved && is_editable;
    const canFinalRecommend = permissions.includes('final_recommend_non_budget') && !is_approved && is_editable;

    details.forEach((o, index) => {
      const row = (
        <tr key={`detail-${index}`}>
          <td>{index + 1}</td>
          <td>
            <div className="input">
              {(is_editable && !is_approved && permissions.includes('edit_non_budget')) && (
                <SelectCoa
                  name="code_of_account"
                  placeholder="Pilih Kode Akun"
                  coa={coa}
                  onSearchCoa={this._searchCoa}
                  onClick={this._onFormChange}
                  value={o.code_of_account}
                  rightIcon="icon-search"
                  fieldName="details"
                  arrayPosition={index}
                  inputArray
                  noMargin
                  edit={is_editable && permissions.includes('edit_non_budget')}
                />
              )}
              {(!is_editable || is_approved || !permissions.includes('edit_non_budget')) && (
                <span>{o.code_of_account.code} - {o.code_of_account.title}</span>
              )}
            </div>
          </td>
          <td>
            <Input
              name="description"
              value={o.description}
              data-field-name="details"
              data-array-position={index}
              onChange={this._onFormChange}
              data-input-array
              edit={is_editable && !is_approved && permissions.includes('edit_non_budget')}
            />
          </td>
          <td className="nominal">
            <Input
              name="amount"
              value={commaFormatted(o.amount)}
              data-field-name="details"
              data-array-position={index}
              onChange={this._onFormChange}
              data-input-array
              data-is-number
              edit={is_editable && !is_approved && permissions.includes('edit_non_budget')}
            />
          </td>
          { hasWorkflow && (
            <td className="nominal">
              { canRecommend && (
                <div className="input">
                  <Input
                    name="recommendation"
                    value={commaFormatted(o.recommendation)}
                    data-field-name="details"
                    data-array-position={index}
                    onChange={this._onFormChange}
                    data-input-array
                    data-is-number
                    edit={is_editable && !is_approved && permissions.includes('recommend_non_budget')}
                  />
                </div>
              )}
              {
                (!canRecommend && hasWorkflow) && (
                  <span>{commaFormatted(o.recommendation)}</span>
                )
              }
            </td>
          )}
          { hasWorkflow && (
            <td className="nominal">
              { canFinalRecommend && (
                <div className="input">
                  <Input
                    name="final_recommendation"
                    value={commaFormatted(o.final_recommendation)}
                    data-field-name="details"
                    data-array-position={index}
                    onChange={this._onFormChange}
                    data-input-array
                    data-is-number
                    edit={is_editable && !is_approved && permissions.includes('final_recommend_non_budget')}
                  />
                </div>
              )}
              {
                !canFinalRecommend && (
                  <span>{commaFormatted(o.final_recommendation)}</span>
                )
              }
            </td>
          )}
          { (is_editable && !is_approved && permissions.includes('edit_non_budget')) && (
            <td width="5%">
              <Button onClick={() => this._onDeleteList(index)} icon="icon-trash-o" />
            </td>
          )}
        </tr>
      );
      rows.push(row);
    });
    return rows;
  }

  render() {
    const { form, permissions, is_editable } = this.state;
    const { location, coa, user } = this.props;
    const { is_approved = false, workflow = [] } = form.value;
    const { user_group } = user;
    const { name: userGroupName } = user_group;
    const { isEdit = false } = location.state;
    const lastWorkflow = workflow[workflow.length - 1];
    const hasWorkflow = workflow.length > 0;
    const fields = FINANCE_ADD_NON_BUDGET_FORM_FIELDS;

    fields.map((o) => {
      if (o.name !== 'request_number' && o.name !== 'date') {
        o.disabled = !is_editable || !permissions.includes('edit_non_budget') || is_approved;
      }
      return o;
    });

    return (
      <div className="manage-non-budget">
        <h1>
          {
            isEdit ? 'Edit Non Anggaran' : 'Buat Non Anggaran'
          }
        </h1>
        <form onSubmit={this._onSubmit}>
          <ListForm
            coa={coa}
            form={form}
            formFields={fields}
            onFormChange={this._onFormChange}
            onSearchCoa={this._searchCoa}
          />
          <table className="table">
            <thead>
              <tr>
                <th>No.</th>
                <th>Kode Akun</th>
                <th>Deskripsi</th>
                <th width="20%">Nominal</th>
                { (hasWorkflow) && (
                  <th width="20%">Rekomendasi</th>
                )}
                { (hasWorkflow) && (
                  <th width="20%">Rekomendasi Akhir</th>
                )}
                { (is_editable && !is_approved && permissions.includes('edit_non_budget')) && (
                  <th width="5%">&nbsp;</th>
                )}
              </tr>
            </thead>
            <tbody>
              <tr>
                { (!is_approved && is_editable && permissions.includes('edit_non_budget')) && (
                  <td colSpan="7">
                    <Button
                      type="button"
                      title="Tambah"
                      onClick={this._onAddList}
                    />
                  </td>
                )}
              </tr>
              { this._renderRows() }
            </tbody>
          </table>
          <div className="manage-non-budget">
            { (!is_approved && is_editable
                && (
                  permissions.includes('edit_non_budget')
                  || permissions.includes('recommend_non_budget')
                  || permissions.includes('final_recommend_non_budget')
                ))
              && (
                <>
                  <Button
                    type="button"
                    title="Simpan"
                    onClick={this._onSubmit}
                  />
                  <br />
                </>
              )
            }
            { (!is_approved
                && is_editable
                && ((lastWorkflow && lastWorkflow.next_role === userGroupName) || workflow.length === 0)
                && (permissions.includes('submit_non_budget')
                || permissions.includes('approve_non_budget'))) && (
                <>
                  <Button
                    type="button"
                    title={permissions.includes('approve_non_budget') ? 'Setujui' : 'Ajukan'}
                    onClick={this._onApproval}
                  />
                  <br />
                </>
            )}
            { (!is_approved
              && is_editable
              && (lastWorkflow && lastWorkflow.next_role === userGroupName)
              && permissions.includes('reject_non_budget')) && (
              <>
                <Button
                  type="button"
                  title="Tolak"
                  onClick={this._onReject}
                />
                <br />
              </>
            )}
          </div>
        </form>
      </div>
    );
  }
}
AddNonBudget.propTypes = {
  handleGetCodeOfAccount: PropTypes.func,
  handleAddNonBudget: PropTypes.func,
  handleEditNonBudget: PropTypes.func,
  handleSubmitNonBudget: PropTypes.func,
  handleGetNonBudget: PropTypes.func,
  handleRejectNonBudget: PropTypes.func,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object,
  coa: PropTypes.object.isRequired,
};
AddNonBudget.defaultProps = {
  handleGetCodeOfAccount: noop,
  handleAddNonBudget: noop,
  handleEditNonBudget: noop,
  handleSubmitNonBudget: noop,
  handleGetNonBudget: noop,
  handleRejectNonBudget: noop,
  user: null,
};

import { connect } from 'react-redux';
import ManageNonBudget from '../../../../views/Finance/Budgeting/NonBudget/ManageNonBudget.finance.view';
import { getCoaOptions } from '../../../../states/thunks/options.thunk';
import { addNonBudget, editNonBudget, submitNonBudget, getNonBudgetById } from '../../../../states/thunks/finance.thunk';

function mapStateToProps(state) {
  return {
    user: state.user,
    coa: state.options.coa,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleGetCodeOfAccount: code => dispatch(getCoaOptions(code)),
    handleGetNonBudget: (payload, unit_id) => dispatch(getNonBudgetById(payload, unit_id)),
    handleAddNonBudget: (payload, goBack, unit_id) => dispatch(addNonBudget(payload, goBack, unit_id)),
    handleEditNonBudget: (payload, goBack, unit_id) => dispatch(editNonBudget(payload, goBack, unit_id)),
    handleSubmitNonBudget: (payload, unit_id) => dispatch(submitNonBudget(payload, unit_id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageNonBudget);

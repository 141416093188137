import React, { PureComponent } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PropTypes from 'prop-types';
import {
  isEmpty,
  map, noop, find, cloneDeep, keys,
} from 'lodash';
import { errorAlert } from '../../../../utils/alert.util';

import CashJournalForm from './components/CashJournalForm.component';
import { commaFormatted, normalizeAmount, dateFormat } from '../../../../utils/transformer.util';
import {
  FINANCE_CASH_JOURNAL_RECONCILIATION_FORM,
  FINANCE_CASH_JOURNAL_STANDARD_FORM,
} from '../../../../constants/finance/finance.constant';
import Button from '../../../../components/base/Button/Button.component';
import CashJournalFormHeader from './components/CashJournalFormHeader.component';
import CashJournalFundRequest from './components/CashJournalFundRequest.component';

const MySwal = withReactContent(Swal);


const _setSelectedFundRequest = (state = {}, checked = false, detailId = null) => {
  const { form } = state;
  const { details = {} } = form.value;
  const newState = { ...state };
  const newRecommendations = newState.form.value.recommendations;

  if (!checked) {
    delete newState.checkedDetails[detailId];
    delete newRecommendations[detailId];
  } else {
    const detail = find(details, (o, key) => parseInt(key, 0) === parseInt(detailId, 0));
    newState.checkedDetails[detailId] = detail;
    newRecommendations[detailId] = detail.amount;
  }
  newState.form.value.recommendations = newRecommendations;

  return newState;
};

export default class ManageCashJournal extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._setForm = this._setForm.bind(this);
    this._onAddList = this._onAddList.bind(this);
    this._onClearForm = this._onClearForm.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._getCoaOptions = this._getCoaOptions.bind(this);
    this._calculateSummary = this._calculateSummary.bind(this);
    this._showModal = this._showModal.bind(this);
    this._onCloseModal = this._onCloseModal.bind(this);
    this._renderFundRequests = this._renderFundRequests.bind(this);
    this._onSelectCoa = this._onSelectCoa.bind(this);
    this._onCheckFundRequest = this._onCheckFundRequest.bind(this);
    this._handleDeleteJournalDetail = this._handleDeleteJournalDetail.bind(this);
    this._setDefaultReconciliation = this._setDefaultReconciliation.bind(this);

    this.state = {
      canAdd: true,
      counter: 0,
      form: {
        value: {
          date: dateFormat(new Date(), 'YYYY-MM-DD'),
          type: '',
          pos: '',
          fund_type: 'BUDGET',
          reconciliation: [],
          standard: [],
        },
        error: {
          reconciliation: [],
          standard: [],
        },
      },
      availableFundRequests: {},
      selectedFundRequests: {},
      summary: {
        totalGross: 0,
        totalNett: 0,
      },
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { isEdit = false, data } = location.state;

    if (isEdit) {
      this._setForm(data.id);
    }

    this._getCoaOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    const { form } = this.state;
    const { value } = form;
    const { form: prevForm } = prevState;
    const { value: prevValue } = prevForm;

    if (prevValue.type !== value.type
      || prevValue.tipe !== value.tipe
      || prevValue.pos !== value.pos
      || prevValue.date !== value.date
      || prevValue.fund_type !== value.fund_type
    ) {
      this._getCoaOptions();
    }
  }

  async _onSelectCoa(coa, index) {
    const { form } = this.state;
    const {
      tipe,
      type,
      fund_type,
      pos,
      date,
      id,
    } = form.value;
    const selectedCoa = coa.code || null;
    const {
      handleGetAvailableFundRequests,
      handleGetNonBudget,
    } = this.props;
    if (tipe != null && tipe.toString() === '1' && type === 'KAS_KELUAR' && pos === 'ypl') {
      let data = {};
      if (fund_type === 'NON_BUDGET') {
        data = await handleGetNonBudget({
          id,
          coa: selectedCoa,
          date: dateFormat(date, 'YYYY-MM-DD'),
        });

        this.setState(prevState => ({
          ...prevState,
          canAdd: selectedCoa && !selectedCoa.toString().startsWith('11'),
          availableNonBudgets: {
            [selectedCoa]: data,
          },
        }), () => {
          if (!isEmpty(data)) {
            this._showModal(selectedCoa, index);
          }
        });
      } else {
        data = await handleGetAvailableFundRequests({
          id,
          coa: selectedCoa,
          date: dateFormat(date, 'YYYY-MM-DD'),
        });

        this.setState(prevState => ({
          ...prevState,
          canAdd: selectedCoa && !selectedCoa.toString().startsWith('11'),
          availableFundRequests: {
            [selectedCoa]: { ...data.fund_requests },
          },
        }), () => {
          if (!isEmpty(data.fund_requests)) {
            this._showModal(selectedCoa, index);
          }
        });
      }
    }
  }

  _showModal(selectedCoa, index) {
    if (selectedCoa) {
      MySwal.fire({
        title: <p>Pilih Permohonan Dana</p>,
        html: this._renderFundRequests(selectedCoa, index),
      });
    }
    return false;
  }

  _onCloseModal(detailState, index) {
    const { form, counter } = this.state;
    const { value } = form;
    const { standard: standardForm } = value;
    const newStandardForm = cloneDeep(standardForm);
    const { selected } = detailState;
    const { non_budget, fund_request } = selected;
    let newIndex = index;
    const selectedLength = keys(non_budget).length - 1 + keys(fund_request).length - 1;

    if ((selectedLength + counter) > 10) {
      errorAlert({
        title: 'Tambah item jurnal gagal',
        message: 'JOURNAL_ITEM_EXCEED_LIMIT',
      });
      return false;
    }

    if (Object.keys(non_budget).length <= 0 && Object.keys(fund_request).length <= 0) {
      errorAlert({
        title: 'Permohonan dana belum dipilih',
        message: 'JOURNAL_NO_FUND_REQUEST',
      });
      newStandardForm.splice(index, 1);

      this.setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            standard: [
              ...newStandardForm,
            ],
          },
        },
      }));

      return false;
    }

    newStandardForm[newIndex].budget_details = [];

    if (non_budget || fund_request) {
      Object.keys(non_budget).forEach((key) => {
        const newItem = cloneDeep(newStandardForm[index]);
        if (index !== newIndex) {
          newItem.non_budget_details_id = key;
        } else {
          newStandardForm[index].non_budget_details_id = key;
        }
        newIndex += 1;
      });

      Object.keys(fund_request).forEach((key) => {
        const newItem = cloneDeep(newStandardForm[index]);
        if (index !== newIndex) {
          newItem.fund_request_detail_id = key;
        } else {
          newStandardForm[index].fund_request_detail_id = key;
        }
        newIndex += 1;
      });
    }

    this.setState(prevState => ({
      ...prevState,
      counter: selectedLength + counter,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          standard: [
            ...newStandardForm,
          ],
        },
      },
    }));

    return true;
  }

  _renderFundRequests(selectedCoa, index) {
    const {
      availableFundRequests = {}, selectedFundRequests = {}, availableNonBudgets = {},
      selectedNonBudgets = {},
      form,
    } = this.state;
    const { value } = form;
    const fundRequests = { ...availableFundRequests[selectedCoa] } || {};
    const nonBudgets = { ...availableNonBudgets[selectedCoa] } || {};

    return (
      <CashJournalFundRequest
        fundRequests={fundRequests}
        nonBudgets={nonBudgets}
        selectedFundRequests={selectedFundRequests}
        selectedNonBudgets={selectedNonBudgets}
        onChange={(detailState) => { this._onCloseModal(detailState, index); }}
        selectedCoa={selectedCoa}
        fundType={value.fund_type}
      />
    );
  }

  _onCheckFundRequest(event) {
    const { target } = event;
    const { value, checked } = target;
    let newState = { ...this.state };

    newState = _setSelectedFundRequest(newState, checked, value);

    this.setState({
      ...newState,
    });
  }

  _calculateSummary() {
    const { form } = this.state;
    const { value } = form;
    const { standard } = value;

    let totalGross = 0;
    let totalNett = 0;

    if (standard && standard.length > 0) {
      standard.forEach((item) => {
        const { nominal } = item;
        totalGross += normalizeAmount(nominal);
        totalNett = totalGross;
      });
    }

    this.setState(prevState => ({
      ...prevState,
      summary: {
        totalGross,
        totalNett,
      },
    }));
  }

  async _getCoaOptions(keyword = '') {
    const { form } = this.state;
    const { value } = form;
    const { tipe: tipeForm, date, fund_type = 'BUDGET'} = value;
    const { handleGetCoaOptions, handleGetJournalCoaOptions } = this.props;
    const excludes = {
      groups: (typeof tipeForm !== 'undefined' && tipeForm.toString() === '2') ? [] : [12900],
      codes: [11101],
    };

    if (value.type === 'KAS_MASUK') {
      if (typeof tipeForm !== 'undefined' && tipeForm.toString() === '1') {
        excludes.groups = excludes.groups.concat([11100, 11200, 11300, 11400]);
        handleGetJournalCoaOptions({
          keyword,
          classes: [40000],
          excludes,
          is_credit: true,
          pos: value.pos,
          journal_type: 'KAS',
          fund_type,
          date: dateFormat(date, 'YYYY-MM-DD'),
        });
      } else if (typeof tipeForm !== 'undefined' && tipeForm.toString() === '2') {
        handleGetCoaOptions({
          keyword,
          groups: [12900],
          excludes,
          is_credit: true,
          journal_type: 'KAS',
          fund_type: 'BUDGET',
          date: dateFormat(date, 'YYYY-MM-DD'),
        });
      }
    } else if (typeof tipeForm !== 'undefined' && tipeForm.toString() === '1') {
      handleGetJournalCoaOptions({
        keyword,
        classes: [50000, 10000, 20000, 30000],
        excludes,
        pos: value.pos,
        journal_type: 'KAS',
        fund_type,
        date: dateFormat(date, 'YYYY-MM-DD'),
      });
    } else if (tipeForm === '2') {
      handleGetCoaOptions({
        keyword,
        groups: [12900],
        excludes,
        journal_type: 'KAS',
        date: dateFormat(date, 'YYYY-MM-DD'),
      });
    }
  }

  async _setForm(id) {
    const { handleGetCashJournal } = this.props;
    try {
      const payload = await handleGetCashJournal({ id });
      const { tipe = '', details = { standard: [], reconciliation: [] } } = payload;
      const { standard, reconciliation } = details;
      this.setState(prevState => ({
        canAdd: !standard.some(el => el.code_of_account.toString().startsWith('11')),
        counter: (tipe !== null && tipe.toString() === '1') ? standard.length : reconciliation.length,
        form: {
          value: {
            id: payload.id,
            date: payload.date,
            type: payload.is_credit ? 'KAS_MASUK' : 'KAS_KELUAR',
            fund_type: payload.fund_type || 'BUDGET',
            pos: payload.pos,
            number: payload.journal_number,
            submitted_by: payload.submitted_by,
            received_by: payload.accepted_by,
            unit: payload.unit_id,
            standard: map(standard, list => ({
              code_of_account: list.parameter_code,
              description: list.description,
              nominal: Number(list.nominal, 0),
              budget_details: list.budget_details || [],
              fund_request_detail_id: list.fund_request_detail_id || null,
              non_budget_details_id: list.non_budget_details_id || null,
            })),
            reconciliation: map(reconciliation, list => ({
              code_of_account: list.parameter_code,
              nominal: Number(list.nominal, 0),
              description: list.description,
            })),
            tipe: tipe ? tipe.toString() : '',
            is_reconciliation: payload.is_reconciliation || false,
          },
          error: {
            reconciliation: [],
            standard: [],
          },
        },
      }), () => {
        this._calculateSummary();
      });
    } catch (err) {
      // err action
    }
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;

    this.setState((prevState) => {
      const { form } = prevState;
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (name === 'tipe' || name === 'type' || name === 'pos' || name === 'fund_type') {
        let { pos } = form.value;
        if ((name === 'tipe' && value === '2')) {
          pos = null;
        }
        if (name === 'pos') {
          pos = value;
        }
        return {
          counter: 0,
          form: {
            value: {
              ...form.value,
              pos,
              [name]: formattedValue,
              reconciliation: [],
              standard: [],
            },
            error: {
              ...form.error,
              [name]: '',
              reconciliation: [],
              standard: [],
            },
          },
        };
      }

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = form.value[fieldName];
        newListError = form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;

        const { code_of_account } = newList[arrayPosition];

        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
        newList[arrayPosition] = {
          ...newList[arrayPosition],
        };
      }

      return {
        ...prevState,
        form: {
          value: {
            ...form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
        selectedCoa: (name === 'code_of_account') ? value.code : prevState.selectedCoa,
      };
    }, () => {
      if (name === 'code_of_account') {
        this._onSelectCoa(value, arrayPosition);
      }
      if (name === 'tipe' && value === '2') {
        this._setDefaultReconciliation();
      }
    });
  }

  _onAddList(fieldName) {
    const { counter } = this.state;
    if (counter < 10) {
      this.setState(prevState => ({
        counter: counter + 1,
        form: {
          value: {
            ...prevState.form.value,
            [fieldName]: [
              ...prevState.form.value[fieldName],
              {
                budget_details: [],
              },
            ],
          },
          error: {
            ...prevState.form.error,
            [fieldName]: [
              ...prevState.form.error[fieldName],
              {},
            ],
          },
        },
      }));
    }
  }

  _onClearForm() {
    this.setState({
      counter: 0,
      form: {
        value: {},
        error: {},
      },
    });
  }

  async _onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const {
      handleManageCashJournal,
      history,
    } = this.props;
    await handleManageCashJournal(form.value, history.goBack);
  }

  _handleDeleteJournalDetail(idx, id, fieldName) {
    const { handleDeleteJournalDetail } = this.props;
    const { form, counter } = this.state;
    const { value } = form;
    const newList = [...value[fieldName]];

    newList.splice(idx, 1);

    if (id) {
      handleDeleteJournalDetail({
        id,
      });
    }

    this.setState(prevState => ({
      ...prevState,
      counter: counter - 1,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          [fieldName]: newList,
        },
      },
    }));
  }

  _setDefaultReconciliation() {
    const { coa = {} } = this.props;
    const { list = [] } = coa;

    const reconciliationCode = list.find(item => item.code.toString() === '12901');

    this.setState(prevState => ({
      counter: 1,
      form: {
        value: {
          ...prevState.form.value,
          reconciliation: [{
            code_of_account: reconciliationCode,
          },
          ],
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }


  render() {
    const {
      form, summary, canAdd,
    } = this.state;
    const { totalGross, totalNett } = summary;
    const {
      coa, location,
      handleGetUnitOptions, user, handleGetDestinationAccount,
    } = this.props;
    const { isEdit } = location.state;
    const {
      reconciliation,
      standard,
      tipe,
      type,
      is_posted,
      is_reconciliation = false,
    } = form.value;
    const {
      reconciliation: reconciliationErrors,
      standard: standardErrors,
    } = form.error;
    return (
      <div className="manage-cash-journal">
        <form onSubmit={this._onSubmit}>
          <CashJournalFormHeader
            form={form}
            coa={coa}
            onFormChange={this._onFormChange}
            isDebit={type === 'KAS_KELUAR'}
            showEdit={isEdit}
            onSearchUnit={handleGetUnitOptions}
            onSearchDestinationAccount={handleGetDestinationAccount}
            user={user}
          />
          {
            type && (tipe === '1' || !isEmpty(standard)) && (
              <div>
                <CashJournalForm
                  isPosted={is_posted}
                  title="Standard"
                  listField={FINANCE_CASH_JOURNAL_STANDARD_FORM}
                  error={standardErrors}
                  list={standard}
                  coa={coa}
                  fieldName="standard"
                  onAddList={this._onAddList}
                  onChange={this._onFormChange}
                  onSearchCoa={this._getCoaOptions}
                  isReconciliation={is_reconciliation}
                  onDeleteJournalDetail={this._handleDeleteJournalDetail}
                  tipe={tipe}
                  canAdd={canAdd}
                />
              </div>
            )
          }
          {
            type && (tipe === '2' || !isEmpty(reconciliation)) && (
              <div>
                <CashJournalForm
                  isPosted={is_posted}
                  coa={coa}
                  title="Rekonsiliasi"
                  listField={FINANCE_CASH_JOURNAL_RECONCILIATION_FORM}
                  error={reconciliationErrors}
                  list={reconciliation}
                  fieldName="reconciliation"
                  onAddList={this._onAddList}
                  onChange={this._onFormChange}
                  onSearchCoa={this._getCoaOptions}
                  isReconciliation={is_reconciliation}
                  onDeleteJournalDetail={this._handleDeleteJournalDetail}
                  tipe={tipe}
                  canAdd={canAdd}
                />
              </div>
            )
          }
          <div className="manage-cash-journal__footer">
            <div>
              <h2>Total Bruto: Rp {commaFormatted(totalGross)}</h2>
              <h2>Total Neto: Rp {commaFormatted(totalNett)}</h2>
            </div>
            <Button
              type="submit"
              title="Simpan"
            />
          </div>
        </form>
      </div>
    );
  }
}

ManageCashJournal.propTypes = {
  handleGetAvailableFundRequests: PropTypes.func,
  handleGetCoaOptions: PropTypes.func,
  handleGetJournalCoaOptions: PropTypes.func,
  handleManageCashJournal: PropTypes.func,
  handleGetCashJournal: PropTypes.func,
  handleGetUnitOptions: PropTypes.func,
  handleDeleteJournalDetail: PropTypes.func,
  handleGetDestinationAccount: PropTypes.func,
  handleGetNonBudget: PropTypes.func,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  coa: PropTypes.object.isRequired,
  user: PropTypes.object,
};
ManageCashJournal.defaultProps = {
  handleGetAvailableFundRequests: noop,
  handleGetCoaOptions: noop,
  handleGetJournalCoaOptions: noop,
  handleManageCashJournal: noop,
  handleGetCashJournal: noop,
  handleGetUnitOptions: noop,
  handleDeleteJournalDetail: noop,
  handleGetDestinationAccount: noop,
  handleGetNonBudget: noop,
  user: {},
};
